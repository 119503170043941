import React from "react";

import styles from "./text-input.module.scss";

interface InputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
}

export function TextInput({ autoFocus, label, value, onChange }: InputProps) {
  return (
    <div className={styles.input}>
      <label className={styles.label}>{label}</label>
      <input
        className={styles.control}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoFocus={autoFocus}
      />
    </div>
  );
}
