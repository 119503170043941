import { TaskEntity, database } from "shared/database";

import { TaskModel } from "./task-model";

export async function getAllTasks() {
  const tasks = await database.tasks.toCollection().sortBy("order");
  const taskChildren = tasksToTaskChildrenMap(tasks);
  return tasks.map((t) => new TaskModel(t, taskChildren));
}

export async function getOutlineTasks() {
  const tasks = await getOutlineTasksQuery().sortBy("order");
  const taskChildren = tasksToTaskChildrenMap(tasks);
  return tasks.map((t) => new TaskModel(t, taskChildren));
}

export async function getOutlineTasksWithPlans() {
  const tasks = await getOutlineTasksQuery().sortBy("order");
  const taskChildren = tasksToTaskChildrenMap(tasks);
  return tasks
    .filter((t) => t.plan !== undefined)
    .map((t) => new TaskModel(t, taskChildren));
}

export function getOutlineTasksQuery() {
  return database.tasks
    .where("location")
    .equals("outline")
    .filter((t) => t.location === "outline");
}

function tasksToTaskChildrenMap(tasks: TaskEntity[]) {
  const map = new Map<string | null, TaskEntity[]>();

  for (const task of tasks) {
    if (map.has(task.parentId)) map.get(task.parentId)!.push(task);
    else map.set(task.parentId, [task]);
  }

  return map;
}
